<template>
    <div class="pd20x flex1">
        <addActive :visible="visible" @close="visible = false" :data="currentItem" @update="getData"></addActive>
        <div class="contentBox h100pct ofA">
            <div class="filter" style="padding:10px 20px">
                <div>
                    <label>搜索类型：</label>
                    <a-select v-model="searchType" style="width: 120px" @change="onChange" class="mr10px" placeholder="请选择搜索类型">
                        <a-select-option value="spec" selected>
                            激活码批次
                        </a-select-option>
                        <a-select-option value="codeNumber">
                            激活码编号
                        </a-select-option>
                    </a-select>
                    <a-input-search placeholder="请输入搜索的内容" v-model="searchInput" style="width: 300px" @search="onSearch" class="mr10px"/>
                </div>
                <div>
                    <a-button type="primary" @click="()=>{ currentItem = null ; visible = true }" style="margin-right: 20px">添加</a-button>
                    <a-button type="info" @click="exportText">展示可用码</a-button>
                </div>
            </div>
            <a-table
                    :columns="columns"
                    :data-source="data"
                    :rowKey="'uid'"
                    :pagination="{pageSize: page.pageSize, total}"
                    @change="changePage"
            >
                <template slot="codeNumber" slot-scope="text">
                    {{text}}
                </template>
                <template slot="validTime" slot-scope="text">
                    {{text}}
                </template>
                <template slot="activeUserInfo" slot-scope="text, record">
                    <div>
                        <img :src="record.headimgurl" style="border-radius: 50%;width:35px">
                        {{record.nickname}}
                    </div>
                </template>
                <template slot="activeTime" slot-scope="text">
                    {{text}}
                </template>
                <template slot="operation" slot-scope="text, record">
                    <a-space>
                        <a-button @click="deleteItem(record.id)">删除</a-button>
                    </a-space>
                </template>
            </a-table>
            <a-modal
                    v-model="showCodes"
                    title="可用激活码"
                    ok-text="点击复制"
                    cancel-text="关闭"
                    @ok="handleOk"
                    @cancel="handleCancel"
            >
                <p>{{ ModalText }}</p>
            </a-modal>
        </div>
    </div>
</template>
<script>
    const columns = [
        { title: '批次', dataIndex: 'spec', align: 'center'},
        { title: '编号', dataIndex: 'codeNumber', align: 'center'},
        { title: '有效时间', dataIndex: 'validTime', align: 'center' },
        { title: '激活用户', dataIndex: 'activeUser',scopedSlots: { customRender: 'activeUserInfo' }, align: 'center' },
        { title: '激活时间', dataIndex: 'activeTime', align: 'center' },
        { title: '操作', dataIndex: 'operation', scopedSlots: { customRender: 'operation' }, align: 'center' }
    ];
    import * as http from '@/libs/practice'
    import addActive from '@/components/practice/addActive'
    export default {
        name: 'activeList',
        components: { addActive },
        data(){
            return{
                searchType : 'spec',
                showCodes : false,
                ModalText : '',
                data:[],
                columns,
                searchInput: '',
                visible : false,
                currentItem: null,
                filter:{},
                page:{
                    pageSize: 15,
                    page: 1
                },
                total: 0
            }
        },
        mounted(){
            this.getData()
        },
        methods:{
            onChange(){

            },
            handleOk(){
                this.$copyText(this.ModalText).then(res=> {
                    this.$message.success('复制成功')
                },error=>{
                    this.$message.success(error)
                    console.log(error)
                })
            },
            handleCancel(){
                this.showCodes = false
            },
            exportText() {
                let filter = {};
                filter['searchInput'] = this.searchInput
                filter['searchType'] = this.searchType
                http.get_active_show({...filter}).then(res => {
                    if(res){
                        this.showCodes = true;
                        this.ModalText = res;
                    }
                })
            },
            getData(){
                http.get_active_list({...this.page, ...this.filter}).then(res => {
                    let {total, data} = res;
                    this.data = data;
                    this.total = total
                })
            },
            deleteItem(id){
                console.log(id);
                let _this = this
                this.$confirm({
                    title: '是否确定删除该条信息?',
                    centered: true,
                    onOk() {
                        http.del_active({id:id}).then(res => {
                            _this.$message.info('删除成功');
                            _this.getData()
                        })
                    },
                });
            },
            onSearch(){
                this.filter = {};
                this.filter['searchInput'] = this.searchInput
                this.filter['searchType'] = this.searchType
                this.getData()
            },
            changePage({current}){
                this.page.page = current
                this.getData()
            }
        }
    }
</script>
