<template>
    <a-modal
            :title="title"
            :visible="visible"
            :confirm-loading="confirmLoading"
            @cancel="$emit('close')"
            @ok="handleSubmit"
            destroyOnClose
    >
        <a-form
                :form="form"
                v-bind="formItemLayout"
                v-if="visible"
        >
            <a-form-item label="激活码批号">
                <a-input v-model="spec" placeholder="请输入生成的激活码批次" />
            </a-form-item>
            <a-form-item label="激活码前缀">
                <a-input v-model="name" placeholder="请输入生成激活码前缀" />
            </a-form-item>
            <a-form-item label="激活码数量">
                <a-input v-model="number" placeholder="请输入生成激活码数量" />
            </a-form-item>
            <a-form-item label="激活码时间">
                <a-input v-model="days" placeholder="请输入激活码可用的天数" />
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
    import { uploadImg } from '@/libs/api'
    import * as http from '@/libs/practice'
    export default {
        name: 'addActive',
        props: [ 'visible', 'data' ],
        beforeCreate() {
            this.form = this.$form.createForm(this);
        },
        watch:{
            visible(val){
                if(val){
                    if(this.data){
                        this.title = "编辑激活码"
                    }else{
                        this.title = "新增激活码";
                        this.spec = '';
                        this.name = '';
                        this.number = '';
                        this.days = ''
                    }
                }
            }
        },
        data(){
            return{
                confirmLoading: false,
                loading: false,
                title: '',
                spec:'',
                name:'',
                days:'',
                number:'',
                formItemLayout: {
                    labelCol: { span: 5 },
                    wrapperCol: { span: 18 },
                },
            }
        },
        methods:{
            handleSubmit(){
                if(!this.number){
                    this.$message.error('请输入激活码生成的数量')
                    return
                }
                this.confirmLoading = true
                http.create_active({spec: this.spec,name: this.name,number: this.number, days : this.days}).then(res=>{
                    this.$emit('close')
                    this.$emit('update')
                    this.confirmLoading = false
                }).catch((error)=>{
                    console.log(error)
                    this.confirmLoading = false
                })
            },
            beforeUpload(){

            },
            customRequest(data){
                let formData = new FormData()
                formData.append('file',data.file)
                uploadImg(formData).then(res=>{
                    if(res){
                        this.imageUrl=res.fileUrl
                    }
                },error=>{
                    console.log(error)
                })
            },
            handleChange(){

            }
        }
    }
</script>
